.swiper-container {
    position: relative;
    padding: 45px;
    margin-left: 10%;
    height: 250px;
    @media (max-width: 750px) {
        height: unset;
        padding: 30px;
    }
}

.swimlane-wrapper {
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
}

.swimlane-wrapper:first-child {
    background-color: #f5f5f5;
    position: relative;
    z-index: 5;
}

/* .swimlane-wrapper:not(:first-child) {
    margin-top: -1.8rem;
} */

.landscape {
    height: auto;
    top: -1rem;
}

.portrait {
    height: 18rem;
}

.swimlane-wrapper .swiper-slide-landscape {
    width: 20rem !important;
    text-align: center;
    font-size: var(--font-size-body-lg);
    /* background: #fff; */
    display: flex !important;
    justify-content: center;
    align-items: center;
    background-size: 100% 100%;

    @media (max-width: 750px) {
        width: 9.5rem !important;
    }
}

.swimlane-wrapper .swiper-slide-landscape-hybrid {
    width: 26.5rem !important;
    text-align: center;
    font-size: var(--font-size-body-lg);
    /* background: #fff; */
    display: flex !important;
    justify-content: center;
    align-items: center;
    background-size: 100% 100%;
}

.swimlane-wrapper .swiper-wrapper:hover {
    z-index: 5;
}

.swimlane-wrapper .swiper-slide-poster {
    width: 12.5rem !important;
    text-align: center;
    font-size: var(--font-size-body-lg);
    /* background: #fff; */
    display: flex !important;
    justify-content: center;
    align-items: center;
    background-size: 100% 100%;

    @media (max-width: 750px) {
        width: 6.5rem !important;
    }
}

.swiper-next-arrow {
    width: 38px !important;
    fill: white;
    height: 60px !important;
    padding: 0 11px;
    @media (max-width: 750px) {
        width: 18px !important;
        height: unset !important;
        padding: 0;
    }
}

.swiper-prev-arrow {
    width: 38px !important;
    fill: white;
    height: 60px !important;
    padding: 0 11px;
    transform: rotate(180deg);
    @media (max-width: 750px) {
        width: 18px !important;
    }
}

.swiper-button-next::after,
.swiper-button-prev::after {
    content: ' ' !important;
}

.swiper-button-prev,
.swiper-button-next {
    background: var(--primary-color);
    border-radius: 50%;
    margin-top: 0px !important;
}

.swiper-button-next:hover .swiper-next-arrow,
.swiper-button-prev:hover .swiper-prev-arrow {
    fill: var(--primary-color);
}

.swiper-button-next:hover {
    transform: translate(10px, 0px) !important;
    transition: all 0.3s !important;
}

.swiper-button-prev:hover {
    transform: translate(-10px, 0px) !important;
    transition: all 0.3s !important;
}

.swiper-button-prev:after,
.swiper-button-next:after {
    font-size: var(--font-size-title-sm) !important;
}

.swimlane-wrapper .swiper-button-prev,
.swimlane-wrapper .swiper-button-next {
    top: 27%;
    width: 80px;
    height: 80px;
    right: -1%;

    @media (max-width: 750px) {
        width: 40px;
        height: 40px;
        right: -1.5%;
    }
}

.swiper-container:hover .swiper-button-next,
.swiper-container:hover .swiper-button-prev {
    opacity: 1 !important;
    animation: none !important;
}

.swiper-container:hover .swiper-button-prev.swiper-button-disabled,
.swiper-container:hover .swiper-button-next.swiper-button-disabled {
    opacity: 0.35 !important;
    visibility: visible;
}

.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
    visibility: hidden;
}

.swimlane_Lable {
    display: flex;
    font-size: var(--font-size-display-sm);
    line-height: 48.3px;
    color: var(--text-color);
    @media (max-width: 750px) {
        font-size: var(--font-size-title-sm);
    }
}

.no_of_tiles {
    background-color: var(--background-color);
    border-radius: 10px;
    color: #000;
    text-align: center;
    font-style: normal;
    line-height: 10px;
    /* 125% */
    display: flex;
    align-items: center;
    font-size: var(--font-size-xxs);
    height: 12px;
    margin: 4px 10px;
    padding: 0 5px;
}

.swiper-backface-hidden .swiper-slide {
    transform: none !important;
}

.soft-bundle-swimlane-title {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--bundle-background-color);
    color: var(--text-color);
    padding-inline: 16px;
    padding-top: 9px;
    padding-bottom: 9px;
    gap: 8px;
    font-size: var(--font-size-heading-md);
    line-height: 29px;
    border-radius: 8px;
    margin-left: 16px;
}

.soft-bundle-swimlane-title-star-icon {
    fill: var(--text-color);
    width: 32px;
    height: 32px;
}

.even-row {
    background-color: var(--description-hover-color) !important;
}

.swimlane-title-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding-top: 80px;
    margin-bottom: 10px;
    @media (max-width: 750px) {
        text-align: center;
    }
}

.swimlane-title-logo {
    width: 80px;
    height: 45px;
    border-radius: 6px;
}
