.offer-Add-to-cart-More-Info-container-for-hard-bundle{
    margin-inline: 15px;
    padding: 5px;
}
.hard-bundle-product-paln-container {
    overflow: auto;
    max-width: 883px;
    max-height: 30vh;
    background-color: white;
    border-radius: 12px;
    color: var(--text-color);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-block: 5px;
    padding-bottom: 15px;
    @media (max-width: 750px) {
        width: 100%;
        height: auto;
    }
}
.hard-bundle-product-paln-top-container {
    margin-top: 20px;
    margin-inline: 20px;
}
.hard-bundle-product-paln-title {
    font-size: var(--font-size-subtitle);
    font-weight: 800;
    color: var(--text-color);;
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 24.61px;
    text-align: left;
}
.hard-bundle-product-paln-price {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}
.hard-bundle-product-paln-price + span {
    font-size: var(--font-size-subtitle-md);
    color: var(--text-color);;
    line-height: 20px;
    text-align: left;
}
.hard-bundle-product-paln-price span:nth-child(2) {
    font-size: var(--font-size-compact);
    color: var(--text-color);
    line-height: 19.64px;
    text-align: left;
    opacity: 70%;
    text-decoration: line-through;
}
.hard-bundle-product-paln-description {
    /* height: 72px; */
    font-size: var(--font-size-body);
    line-height: 20.94px;
    text-align: left;
    margin-bottom: 10px;
    transition: background ease-in-out 0.2s;
    border-radius: 12px;
    padding: 5px;
}
.hard-bundle-product-paln-description:hover {
    background: var(--description-hover-color);
    cursor: pointer;
}

.hard-bundle-product-paln-middle-container-for-hard-bundle {
    margin-top: 7px;
    margin-inline: 20px;
    max-width: 840px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    @media (max-width: 850) {
        display: flex;
        flex-direction: column;
    }
}
.hard-bundle-product-paln-middle-container-box-for-hard-bundle {
    width: 382px;
    position: relative;
    @media (max-width: 950px) {
        width: 850px;
    }
}
.hard-bundle-product-paln-middle-container-box-for-hard-bundle-for-last-element {
    width: 100%;
    position: relative;
    @media (max-width: 950px) {
        max-width: 850px;
    }
}
.hard-bundle-product-paln-middle-container-box-header-for-hard-bundle {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.hard-bundle-product-paln-middle-container-box-header-for-hard-bundle > span {
    font-size: var(--font-size-body-lg);
    line-height: 27.49px;
    text-align: left;
}
.hard-bundle-logo-img {
    width: 53.33px;
    height: 30px;
    border-radius: 4px;
    margin-top: 6px;
}
.hard-bundle-product-paln-middle-container-box-description-for-hard-bundle {
    font-size: var(--font-size-compact);
    color: var(--text-color);;
    line-height: 20.94px;
    text-align: left;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    display: -webkit-box;
    transition: background ease-in-out 0.4s;
    border-radius: 12px;
    padding: 5px 5px 0;
}
.hard-bundle-product-paln-middle-container-box-description-for-hard-bundle.show {
    -webkit-line-clamp: unset;
}
.hard-bundle-product-paln-middle-container-box-description-for-hard-bundle:hover {
    background: var(--description-hover-color);
    cursor: pointer;
}
.hard-bundle-product-paln-middle-container-box-more-link-for-hard-bundle {
    display: flex;
    justify-content: flex-start;
    gap: 4px;
    margin-top: 10px;
    width: 100%;
    margin-bottom: 16px;
}

.hard-bundle-product-paln-last-more-link{
    display: flex;
    justify-content: center;
    gap: 4px;
    margin-top: 10px;
    bottom: 0;
    width: 100%;
    margin-bottom: 16px;
}
.offer-more-info-icon-for-hard-bundle {
    margin-right: 5px;
    fill: var(--primary-color);
    width: 17px;
    height: 17px;
}
.hard-bundle-product-paln-last-more-link > a,
.hard-bundle-product-paln-middle-container-box-more-link-for-hard-bundle > a {
    font-size: var(--font-size-compact);
    color: var(--primary-color);
    text-decoration: underline;
    cursor: pointer;
    line-height: 21px;
    text-align: left;
}
.vertical-divider {
    width: 1px;
    background: var(--divider-color);
    position: relative;
    bottom: 11px;
    @media (max-width: 850px) {
        display: none;
    }
}
.product-plan-divider-hard-bundle {
    width: 100%;
    height: 1px;
    /* background: rgba(233, 233, 233, 1); */
    background: var(--divider-color);
    margin-top: -11px;
    margin-bottom: 10px;
    @media (max-width: 750px) {
        display: none;
    }
}
.product-plan-divider-hard-bundle-for-last-element {
    width: 100%;
    height: 1px;
    /* background: rgba(233, 233, 233, 1); */
    background: var(--divider-color);
    margin-top: 15px;
    margin-bottom: 10px;
    @media (max-width: 750px) {
        display: none;
    }
}
.product-plan-divider-hard-bundle-for-mobile-view {
    display: none;
    @media (max-width: 850px) {
        display: block;
        width: 100%;
        height: 1px;
        background: var(--description-hover-color);
        margin-top: -11px;
        margin-bottom: 10px;
    }
}
.hard-bundle-product-paln-bottom-container {
    margin-inline: 20px;
}
.hard-bundle-product-paln-bottom-box {
    max-width: 840px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    padding-right: 13px;
}
.hard-bundle-product-paln-bottom-box-nolink {
    max-width: 840px;
    height: 54px;
    display: flex;
    justify-content: flex-end;
}
.deep-link {
    display: flex;
    justify-content: flex-start;
    gap: 4px;
    margin: 10px 0 0 0;
}

.deep-link > a {
    font-size: var(--font-size-body);
    color: var(--primary-color);
    text-decoration: underline;
    cursor: pointer;
    line-height: 21px;
    text-align: left;
}
.add-to-cart {
    max-width: 131px;
    max-height: 40px;
}
.add-to-cart-btn {
    padding: 9px 18px;
    border-radius: 25px;
    background: var(--primary-color);
    font-size: var(--font-size-body-xl);
    line-height: 22.27px;
    text-align: center;
    color: var(--primary-text-color);
    border: none;
    cursor: pointer;
    height: 40px;
    width: 145px;
    @media (max-width: 850px) {
        padding: 8px;
    }
}
.add-to-cart-btn:hover {
    background: var(--background-color);
    color: var(--primary-color);
    border: 2px solid var(--primary-color);
    padding: 8px;
}
.product-plan-container {
    width: 410px;
    height: auto;
    background-color: var(--background-color);
    border-radius: 12px;
    color: var(--text-color);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media (max-width: 750px) {
        width: 100%;
    }
}

.product-plan-sub-container {
    margin-top: 20px;
    margin-inline: 20px;
}

.product-plan-title {
    font-size: var(--font-size-subtitle-md);
    line-height: 27.49px;
    color: var(--activated-text-color);
    display: flex;
    gap: 10px;

    @media (max-width: 750px) {
        font-size: var(--font-size-body-lg);
    }
}

.product-plan-subtitle {
    font-size: var(--font-size-body-lg);
    color: rgba(74, 74, 74, 1);

    @media (max-width: 750px) {
        font-size: var(--font-size-body);
    }
}

.product-plan-divider {
    width: 100%;
    height: 1px;
    /* background-color: rgba(233, 233, 233, 1); */
    background-color: var(--divider-color);
    margin-top: 15px;
    margin-bottom: 10px;

    @media (max-width: 750px) {
        margin-top: 10px;
        margin-bottom: 8px;
    }
}

.product-plan-description {
    font-size: var(--font-size-body);
    line-height: 20.94px;
    padding: 5px;
    transition: background ease-in-out 0.4s;
    border-radius: 12px;
    padding-inline: 5px;
}
.product-plan-description:hover {
    background: var(--description-hover-color);
    cursor: pointer;
}

.product-plan-bottom-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-inline: 20px;
    margin-bottom: 20px;
}

.product-plan-price {
    font-size: var(--font-size-subtitle-md);
    line-height: 20px;
    color: var(--text-color);
    display: flex;
    align-items: center;
    gap: 10px;
}

.product-plan-discount-price {
    font-size: var(--font-size-compact);
    color: var(--text-color);
}

.product-plan-btn {
    flex-direction: row;
    padding: 9px;
    padding-inline: 18px;
    border-radius: 25px;
    line-height: 17.02px;
    font-size: var(--font-size-caption);
    cursor: pointer;
    border-style: solid;
    border-width: 2px;
    border-color: transparent;
    @media (max-width: 750px) {
        font-size: var(--font-size-small-text);
    }
}

.product-plan-add-to-cart {
    background-color: var(--primary-color);
    color: var(--primary-text-color);
}

.product-plan-add-to-cart:hover {
    border-color: var(--primary-color);
    background-color: var(--background-color);
    color: var(--primary-color);
    transition: ease 0.2s;
}

.product-plan-active-btn {
    color: #f9d207;
    border: 2px solid #f9d207;
    background-color: var(--background-color);
}
.product-plan-active-btn:hover {
    background-color: #f9d207;
    color: var(--primary-text-color);
    transition: ease-out 0.2s;
}

.product-plan-resume-btn,
.product-plan-reinstate-btn {
    color: var(--primary-text-color);
    border: 2px solid var(--primary-color);
    background-color: var(--primary-color);
}

.product-plan-resume-btn:hover {
    background-color: var(--background-color);
    color: var(--primary-color);
    transition: ease-out 0.2s;
}
